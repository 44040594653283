import { render, staticRenderFns } from "./PatientList.vue?vue&type=template&id=f5e0e5c2&scoped=true"
import script from "./PatientList.vue?vue&type=script&lang=js"
export * from "./PatientList.vue?vue&type=script&lang=js"
import style0 from "./PatientList.vue?vue&type=style&index=0&id=f5e0e5c2&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f5e0e5c2",
  null
  
)

export default component.exports